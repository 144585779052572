import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { JSONEditor } from '@json-editor/json-editor';
import '@json-editor/json-editor/src/style.css';
import * as utilities from '../utilities';

const Config = (props) => {

  const editorRef = useRef(null);
  const [resultConfig, setResultConfig] = useState({});
  const [initialConfig, setInitialConfig] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const fetchConfig = async () => {
    const response = await fetch(props.configLink + '/?rawdata=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })

    if (response.ok) {
      const result = await response.json();
      setResultConfig(JSON.parse(result.config));
      setInitialConfig((result.config));
    } else {
      utilities.addToast('Unable to load configuration', 5, 'danger');
    }

  };

  const saveConfig = async () => {
    const response = await fetch(props.configLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: (resultConfig)
    })
    if (response.ok) {
      utilities.addToast('Configuration saved', 5, 'success');
      setIsSaving(false);
    } else {
      utilities.addToast('Unable to save configuration', 5, 'danger');
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [])

  useEffect(() => {

    if (Object.keys(resultConfig).length === 0 || isLoaded) return;

    // Define your JSON schema
    const schema = {
      type: "object",
      title: " ",
      id: "settings",
      options: {
        "disable_properties": true,
        "disable_collapse": true
      },
      "properties": {
        "LaborNames": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Labor Names",
          "uniqueItems": true,
          "items": {
            "id": "Name",
            "type": "object",
            "properties": {
              "FirstName": {
                "title": "First Name",
                "type": "string"
              },
              "LastName": {
                "title": "Last Name",
                "type": "string"
              },
              "FirstLast": {
                "type": "string",
                "template": "{{lastName}}, {{firstName}}",
                "options": {
                  "hidden": true
                },
                "watch": {
                  "firstName": "Name.FirstName",
                  "lastName": "Name.LastName"
                }
              }
            }
          }
        },
        "LaborEquipmentOperated": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Equipment Operated",
          "uniqueItems": true,
          "items": {
            "title": "Equipment",
            "type": "string"
          }
        },
        "LaborEquipmentNumbers": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Equipment Numbers",
          "uniqueItems": false,
          "items": {
            "type": "object",
            "id": "EquipmentNumber",
            "properties": {
              "EquipmentNumberPrefix1": {
                "title": "#",
                "type": "string",
                "maxLength": 5,
                "minLength": 2,
                "options": {
                  "input_width": "80px"
                }
              },
              "EquipmentNumberPrefix2": {
                "title": "##",
                "type": "string",
                "maxLength": 2,
                "minLength": 0,
                "options": {
                  "input_width": "70px"
                }
              },
              "EquipmentNumberName": {
                "title": "Equipment Number Name",
                "type": "string",
                "options": {
                  "input_width": "350px"
                }
              },
              "EquipmentNumberFull": {
                "type": "string",
                "title": "Full Equipment Number",
                "template": "#{{EquipmentNumberPrefix1}} {{EquipmentNumberPrefix2}} {{EquipmentNumberName}}",
                "options": {
                  "hidden": false,
                  "input_width": "350px"
                },
                "watch": {
                  "EquipmentNumberPrefix1": "EquipmentNumber.EquipmentNumberPrefix1",
                  "EquipmentNumberPrefix2": "EquipmentNumber.EquipmentNumberPrefix2",
                  "EquipmentNumberName": "EquipmentNumber.EquipmentNumberName"
                }
              }
            }
          }
        },
        "EquipmentRentalsCompany": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Equipment Rentals Companies",
          "uniqueItems": true,
          "items": {
            "type": "string",
            "title": "Company",
            "options": {
              "input_width": "200px"
            }
          }
        },
        "HaulingCo": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Hauling Co.",
          "uniqueItems": true,
          "items": {
            "type": "string",
            "title": "Co."
          }
        },
        "SuppliesAndMaterialsName": {
          "type": "array",
          "format": "table",
          "options": {
            "collapsed": true,
            "disable_array_delete_all_rows": true,
            "disable_array_delete_last_row": true
          },
          "title": "Supplies and Materials Name.",
          "uniqueItems": true,
          "items": {
            "type": "string",
            "title": "Name"
          }
        }
      }
    }

    // Create JSON Editor instance
    const editor = new JSONEditor(editorRef.current, {
      schema,
      disable_edit_json: true
      // theme: 'html',
      // iconlib: "fontawesome4"
    });

    editor.on('change', () => {
      setResultConfig(JSON.stringify(editor.getValue()))
      // setShowSaveButton(true);
    });

    setIsLoaded(true);

    editor.on('ready', () => {
      // Now you can interact with the editor
      // Optionally, you can set an initial value
      // const initialValue = {
      //   "LaborNames": [
      //     { "FirstName": "Art", "LastName": "Alvarez", "FirstLast": "Alvarez, Art" },
      //     { "FirstName": "Jose", "LastName": "Arechiga", "FirstLast": "Arechiga, Jose" },
      //     { "FirstName": "Gabriel", "LastName": "Arreguin", "FirstLast": "Arreguin, Gabriel" },
      //     { "FirstName": "Agustin", "LastName": "Cardenas", "FirstLast": "Cardenas, Agustin" },
      //     { "FirstName": "Fernando", "LastName": "Cardenas", "FirstLast": "Cardenas, Fernando" },
      //     { "FirstName": "Luis", "LastName": "Castellanos", "FirstLast": "Castellanos, Luis" },
      //     { "FirstName": "Alex", "LastName": "Contreras", "FirstLast": "Contreras, Alex" },
      //     { "FirstName": "Michael", "LastName": "Correia", "FirstLast": "Correia, Michael" },
      //     { "FirstName": "Kyle", "LastName": "Crow", "FirstLast": "Crow, Kyle" },
      //     { "FirstName": "Jose ", "LastName": "Fuentes", "FirstLast": "Fuentes, Jose " },
      //     { "FirstName": "Felipe", "LastName": "Granados", "FirstLast": "Granados, Felipe" },
      //     { "FirstName": "Pablo", "LastName": "Granados", "FirstLast": "Granados, Pablo" },
      //     { "FirstName": "Mike", "LastName": "Halloran", "FirstLast": "Halloran, Mike" },
      //     { "FirstName": "Jose", "LastName": "Hernandez", "FirstLast": "Hernandez, Jose" },
      //     { "FirstName": "Consuela", "LastName": "Lawson", "FirstLast": "Lawson, Consuela" },
      //     { "FirstName": "Kenny", "LastName": "Lyons", "FirstLast": "Lyons, Kenny" },
      //     { "FirstName": "Jaime", "LastName": "Mendoza", "FirstLast": "Mendoza, Jaime" },
      //     { "FirstName": "Joel", "LastName": "Mendoza", "FirstLast": "Mendoza, Joel" },
      //     { "FirstName": "Jose", "LastName": "Mendoza", "FirstLast": "Mendoza, Jose" },
      //     { "FirstName": "Danny", "LastName": "Origel", "FirstLast": "Origel, Danny" },
      //     { "FirstName": "Rene", "LastName": "Origel", "FirstLast": "Origel, Rene" },
      //     { "FirstName": "Francisco", "LastName": "Ornelas", "FirstLast": "Ornelas, Francisco" },
      //     { "FirstName": "Albert", "LastName": "Ortiz", "FirstLast": "Ortiz, Albert" },
      //     { "FirstName": "Paco", "LastName": "Recinos", "FirstLast": "Recinos, Paco" },
      //     { "FirstName": "Raymond", "LastName": "Schenone", "FirstLast": "Schenone, Raymond" },
      //     { "FirstName": "Nate", "LastName": "Watson", "FirstLast": "Watson, Nate" },
      //     { "FirstName": "Gavin ", "LastName": "Bright", "FirstLast": "Bright, Gavin " },
      //     { "FirstName": "Jose ", "LastName": "Reynaga", "FirstLast": "Reynaga, Jose " },
      //     { "FirstName": "Genaro", "LastName": "Gutierrez", "FirstLast": "Gutierrez, Genaro" },
      //     { "FirstName": "Jose", "LastName": "Navarro", "FirstLast": "Navarro, Jose" },
      //     { "FirstName": "Francisco", "LastName": "Silva", "FirstLast": "Silva, Francisco" }
      //   ],
      //   "LaborEquipmentOperated": [
      //     "Foreman",
      //     "Truck",
      //     "Labor",
      //     "Blade",
      //     "Loader/Scraper",
      //     "Scraper",
      //     "Mini Excavator", "Excavator", "Compactor", "Roller",
      //     "Loader", "Paver", "Water Truck", "Skid Steer", "Sweeper",
      //     "Dump Truck", "Backhoe", "Crane", "Forklift", "Boom Lift", "Rental", "Operator"
      //   ],
      //   "LaborEquipmentNumbers": [
      //     { "EquipmentNumberPrefix1": "1002", "EquipmentNumberPrefix2": "16", "EquipmentNumberName": "FORD F250 SUPERDUTY", "EquipmentNumberFull": "#1002 16 FORD F250 SUPERDUTY" },
      //     { "EquipmentNumberPrefix1": "1005", "EquipmentNumberPrefix2": "18", "EquipmentNumberName": "FORD F250 SUPERDUTY", "EquipmentNumberFull": "#1005 18 FORD F250 SUPERDUTY" },
      //     { "EquipmentNumberPrefix1": "1008", "EquipmentNumberPrefix2": "19", "EquipmentNumberName": "FORD F250 SUPERDUTY", "EquipmentNumberFull": "#1008 19 FORD F250 SUPERDUTY" },
      //     { "EquipmentNumberPrefix1": "1011", "EquipmentNumberPrefix2": "20", "EquipmentNumberName": "FORD F250 SC 4X2", "EquipmentNumberFull": "#1011 20 FORD F250 SC 4X2" },
      //     { "EquipmentNumberPrefix1": "1016", "EquipmentNumberPrefix2": "22", "EquipmentNumberName": "FORD F250 SUPERDUTY", "EquipmentNumberFull": "#1016 22 FORD F250 SUPERDUTY" },
      //     { "EquipmentNumberPrefix1": "16", "EquipmentNumberPrefix2": "12", "EquipmentNumberName": "FORD F250 SUPERDUTY", "EquipmentNumberFull": "#16 12 FORD F250 SUPERDUTY" },
      //     { "EquipmentNumberPrefix1": "37", "EquipmentNumberPrefix2": "11", "EquipmentNumberName": "CHEV 2500", "EquipmentNumberFull": "#37 11 CHEV 2500" },
      //     { "EquipmentNumberPrefix1": "40", "EquipmentNumberPrefix2": "15", "EquipmentNumberName": "CHEV 2500", "EquipmentNumberFull": "#40 15 CHEV 2500" },
      //     { "EquipmentNumberPrefix1": "43", "EquipmentNumberPrefix2": "15", "EquipmentNumberName": "CHEV", "EquipmentNumberFull": "#43 15 CHEV" },
      //     { "EquipmentNumberPrefix1": "44", "EquipmentNumberPrefix2": "16", "EquipmentNumberName": "FORD F-550", "EquipmentNumberFull": "#44 16 FORD F-550" },
      //     { "EquipmentNumberPrefix1": "45", "EquipmentNumberPrefix2": "19", "EquipmentNumberName": "FORD F750", "EquipmentNumberFull": "#45 19 FORD F750" },
      //     { "EquipmentNumberPrefix1": "48", "EquipmentNumberPrefix2": "04", "EquipmentNumberName": "FORD F-750", "EquipmentNumberFull": "#48 04 FORD F-750" },
      //     { "EquipmentNumberPrefix1": "50", "EquipmentNumberPrefix2": "19", "EquipmentNumberName": "PETERBILT SERVICE TRUCK", "EquipmentNumberFull": "#50 19 PETERBILT SERVICE TRUCK" },
      //     { "EquipmentNumberPrefix1": "51", "EquipmentNumberPrefix2": "19", "EquipmentNumberName": "PETERBILT Roll Back", "EquipmentNumberFull": "#51 19 PETERBILT Roll Back" },
      //     { "EquipmentNumberPrefix1": "52", "EquipmentNumberPrefix2": "22", "EquipmentNumberName": "FORD F750", "EquipmentNumberFull": "#52 22 FORD F750" },
      //     { "EquipmentNumberPrefix1": "112", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TRAILER", "EquipmentNumberFull": "#112  TRAILER" },
      //     { "EquipmentNumberPrefix1": "113", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "DUMP TRAILER", "EquipmentNumberFull": "#113  DUMP TRAILER" },
      //     { "EquipmentNumberPrefix1": "122", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TRAILER", "EquipmentNumberFull": "#122  TRAILER" },
      //     { "EquipmentNumberPrefix1": "124", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TRAILER", "EquipmentNumberFull": "#124  TRAILER" },
      //     { "EquipmentNumberPrefix1": "126", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TRAILER", "EquipmentNumberFull": "#126  TRAILER" },
      //     { "EquipmentNumberPrefix1": "140", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "WATER WAGON", "EquipmentNumberFull": "#140  WATER WAGON" },
      //     { "EquipmentNumberPrefix1": "200", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "BLADE 140M3", "EquipmentNumberFull": "#200  BLADE 140M3" },
      //     { "EquipmentNumberPrefix1": "201", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "BLADE 140M3", "EquipmentNumberFull": "#201  BLADE 140M3" },
      //     { "EquipmentNumberPrefix1": "202", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "BLADE 140M", "EquipmentNumberFull": "#202  BLADE 140M" },
      //     { "EquipmentNumberPrefix1": "210", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "BLADE 140M3", "EquipmentNumberFull": "#210  BLADE 140M3" },
      //     { "EquipmentNumberPrefix1": "211", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "BLADE 140M3", "EquipmentNumberFull": "#211  BLADE 140M3" },
      //     { "EquipmentNumberPrefix1": "215 ", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "Cat D6T Dozer", "EquipmentNumberFull": "#215   Cat D6T Dozer" },
      //     { "EquipmentNumberPrefix1": "230", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "SKID STEER 279D3", "EquipmentNumberFull": "#230  SKID STEER 279D3" },
      //     { "EquipmentNumberPrefix1": "236", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N", "EquipmentNumberFull": "#236  LDR/SCRPR CASE 570N" },
      //     { "EquipmentNumberPrefix1": "237", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N ", "EquipmentNumberFull": "#237  LDR/SCRPR CASE 570N " },
      //     { "EquipmentNumberPrefix1": "238", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N", "EquipmentNumberFull": "#238  LDR/SCRPR CASE 570N" },
      //     { "EquipmentNumberPrefix1": "239", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570MXT", "EquipmentNumberFull": "#239  LDR/SCRPR CASE 570MXT" },
      //     { "EquipmentNumberPrefix1": "240", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N", "EquipmentNumberFull": "#240  LDR/SCRPR CASE 570N" },
      //     { "EquipmentNumberPrefix1": "241", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N", "EquipmentNumberFull": "#241  LDR/SCRPR CASE 570N" },
      //     { "EquipmentNumberPrefix1": "242", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570MXT", "EquipmentNumberFull": "#242  LDR/SCRPR CASE 570MXT" },
      //     { "EquipmentNumberPrefix1": "243", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N", "EquipmentNumberFull": "#243  LDR/SCRPR CASE 570N" },
      //     { "EquipmentNumberPrefix1": "244", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570N", "EquipmentNumberFull": "#244  LDR/SCRPR CASE 570N" },
      //     { "EquipmentNumberPrefix1": "246", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LDR/SCRPR CASE 570MXT", "EquipmentNumberFull": "#246  LDR/SCRPR CASE 570MXT" },
      //     { "EquipmentNumberPrefix1": "263", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LOADER CASE 621F", "EquipmentNumberFull": "#263  LOADER CASE 621F" },
      //     { "EquipmentNumberPrefix1": "264", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "LOADER CAT 972K", "EquipmentNumberFull": "#264  LOADER CAT 972K" },
      //     { "EquipmentNumberPrefix1": "275", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "SCRAPER 613C", "EquipmentNumberFull": "#275  SCRAPER 613C" },
      //     { "EquipmentNumberPrefix1": "276", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "SCRAPER 613C", "EquipmentNumberFull": "#276  SCRAPER 613C" },
      //     { "EquipmentNumberPrefix1": "278", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "EXCAVATOR CAT 336F", "EquipmentNumberFull": "#278  EXCAVATOR CAT 336F" },
      //     { "EquipmentNumberPrefix1": "279", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "EXCAVATOR YANMAR SV-100", "EquipmentNumberFull": "#279  EXCAVATOR YANMAR SV-100" },
      //     { "EquipmentNumberPrefix1": "284", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "EXCAVATOR HITACHI 330", "EquipmentNumberFull": "#284  EXCAVATOR HITACHI 330" },
      //     { "EquipmentNumberPrefix1": "289", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "MINI EX YANMAR 35", "EquipmentNumberFull": "#289  MINI EX YANMAR 35" },
      //     { "EquipmentNumberPrefix1": "290", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "MINI EX YANMAR 35", "EquipmentNumberFull": "#290  MINI EX YANMAR 35" },
      //     { "EquipmentNumberPrefix1": "291", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "MINI EX YANMAR 35", "EquipmentNumberFull": "#291  MINI EX YANMAR 35" },
      //     { "EquipmentNumberPrefix1": "292", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "MINI EX YANMAR 35", "EquipmentNumberFull": "#292  MINI EX YANMAR 35" },
      //     { "EquipmentNumberPrefix1": "293", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "MINI EX YANMAR 80", "EquipmentNumberFull": "#293  MINI EX YANMAR 80" },
      //     { "EquipmentNumberPrefix1": "294", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "MINI EX YANMAR 80", "EquipmentNumberFull": "#294  MINI EX YANMAR 80" },
      //     { "EquipmentNumberPrefix1": "295", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "EXCAVATOR 100", "EquipmentNumberFull": "#295  EXCAVATOR 100" },
      //     { "EquipmentNumberPrefix1": "296", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "EXCAVATOR 100", "EquipmentNumberFull": "#296  EXCAVATOR 100" },
      //     { "EquipmentNumberPrefix1": "298", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER DD35B", "EquipmentNumberFull": "#298  ROLLER DD35B" },
      //     { "EquipmentNumberPrefix1": "299", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER DD35B", "EquipmentNumberFull": "#299  ROLLER DD35B" },
      //     { "EquipmentNumberPrefix1": "300", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "DD30B ROLLER", "EquipmentNumberFull": "#300  DD30B ROLLER" },
      //     { "EquipmentNumberPrefix1": "301", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER CAT CS44B", "EquipmentNumberFull": "#301  ROLLER CAT CS44B" },
      //     { "EquipmentNumberPrefix1": "303", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "CAT ROLLER 84", "EquipmentNumberFull": "#303  CAT ROLLER 84" },
      //     { "EquipmentNumberPrefix1": "305", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER HYSTER", "EquipmentNumberFull": "#305  ROLLER HYSTER" },
      //     { "EquipmentNumberPrefix1": "307", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER IR DD-24", "EquipmentNumberFull": "#307  ROLLER IR DD-24" },
      //     { "EquipmentNumberPrefix1": "312", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "COMPACTOR CAT CP44", "EquipmentNumberFull": "#312  COMPACTOR CAT CP44" },
      //     { "EquipmentNumberPrefix1": "316", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "COMPRESSOR SULLAIR", "EquipmentNumberFull": "#316  COMPRESSOR SULLAIR" },
      //     { "EquipmentNumberPrefix1": "317", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "COMPACTOR DYNAPAC", "EquipmentNumberFull": "#317  COMPACTOR DYNAPAC" },
      //     { "EquipmentNumberPrefix1": "318", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER DYNAPAC", "EquipmentNumberFull": "#318  ROLLER DYNAPAC" },
      //     { "EquipmentNumberPrefix1": "319", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER VOLVO DD-31HF", "EquipmentNumberFull": "#319  ROLLER VOLVO DD-31HF" },
      //     { "EquipmentNumberPrefix1": "320", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER VOLVO DD-30B", "EquipmentNumberFull": "#320  ROLLER VOLVO DD-30B" },
      //     { "EquipmentNumberPrefix1": "321", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "ROLLER VOLVO DD-29", "EquipmentNumberFull": "#321  ROLLER VOLVO DD-29" },
      //     { "EquipmentNumberPrefix1": "330", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "PAVER CARLSON CP-100", "EquipmentNumberFull": "#330  PAVER CARLSON CP-100" },
      //     { "EquipmentNumberPrefix1": "331", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "PAVER CR-552", "EquipmentNumberFull": "#331  PAVER CR-552" },
      //     { "EquipmentNumberPrefix1": "332", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "PAVER CARLSON CP-85", "EquipmentNumberFull": "#332  PAVER CARLSON CP-85" },
      //     { "EquipmentNumberPrefix1": "359", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TOYOTA FORKLIFT", "EquipmentNumberFull": "#359  TOYOTA FORKLIFT" },
      //     { "EquipmentNumberPrefix1": "362", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "FORKLIFT KOMATSU", "EquipmentNumberFull": "#362  FORKLIFT KOMATSU" },
      //     { "EquipmentNumberPrefix1": "363", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "WATER TOWER 10,000 GAL", "EquipmentNumberFull": "#363  WATER TOWER 10,000 GAL" },
      //     { "EquipmentNumberPrefix1": "364", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "PWR BUGGY WHITEMAN", "EquipmentNumberFull": "#364  PWR BUGGY WHITEMAN" },
      //     { "EquipmentNumberPrefix1": "365", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "JUMPING JACK", "EquipmentNumberFull": "#365  JUMPING JACK" },
      //     { "EquipmentNumberPrefix1": "367", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "JUMPING JACK", "EquipmentNumberFull": "#367  JUMPING JACK" },
      //     { "EquipmentNumberPrefix1": "370", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "JUMPING JACK", "EquipmentNumberFull": "#370  JUMPING JACK" },
      //     { "EquipmentNumberPrefix1": "371", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "JUMPING JACK", "EquipmentNumberFull": "#371  JUMPING JACK" },
      //     { "EquipmentNumberPrefix1": "372", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "WACKER/RAMMER/JJ", "EquipmentNumberFull": "#372  WACKER/RAMMER/JJ" },
      //     { "EquipmentNumberPrefix1": "373", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "WACKER/RAMMER/JJ", "EquipmentNumberFull": "#373  WACKER/RAMMER/JJ" },
      //     { "EquipmentNumberPrefix1": "374", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "WACKER/RAMMER/JJ", "EquipmentNumberFull": "#374  WACKER/RAMMER/JJ" },
      //     { "EquipmentNumberPrefix1": "375", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "WACKER/RAMMER/JJ", "EquipmentNumberFull": "#375  WACKER/RAMMER/JJ" },
      //     { "EquipmentNumberPrefix1": "400", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#400  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "401", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#401  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "402", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#402  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "403", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#403  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "405", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#405  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "406", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#406  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "407", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBRAPLATE", "EquipmentNumberFull": "#407  VIBRAPLATE" },
      //     { "EquipmentNumberPrefix1": "408", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "VIBER PLATE", "EquipmentNumberFull": "#408  VIBER PLATE" },
      //     { "EquipmentNumberPrefix1": "550", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "DUMPER", "EquipmentNumberFull": "#550  DUMPER" },
      //     { "EquipmentNumberPrefix1": "551", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "DUMPER", "EquipmentNumberFull": "#551  DUMPER" },
      //     { "EquipmentNumberPrefix1": "650", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "POWER BROOM LAYMOR", "EquipmentNumberFull": "#650  POWER BROOM LAYMOR" },
      //     { "EquipmentNumberPrefix1": "660", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "SCISSOR LIFT", "EquipmentNumberFull": "#660  SCISSOR LIFT" },
      //     { "EquipmentNumberPrefix1": "700", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TACKER", "EquipmentNumberFull": "#700  TACKER" },
      //     { "EquipmentNumberPrefix1": "702", "EquipmentNumberPrefix2": "", "EquipmentNumberName": "TACKER (NEW)", "EquipmentNumberFull": "#702  TACKER (NEW)" }
      //   ],
      //   "EquipmentRentalsCompany": [
      //     "Nor-cal Rents", "Sunstate", "Ideal Equipment", "JS Cole", "H&E Rentals", "Mecom", "United Equipment",
      //     "River City Rentals", "Kiote Equipment", "United Rentals", "Ecco", "Bogetti Water Trucks", "Holt of California"
      //   ],
      //   "HaulingCo": [
      //     "Hammer Move In", "Hammer Move Out", "Alegre Import", "Alegre Off Haul", "S&L Move In",
      //     "S&L Move Out", "S&L Off Haul", "S&L Import", "BOGETTI WATER TRUCK"]
      // }
      editor.setValue(resultConfig);
    });

    // return () => {
    //   // Clean up when the component is unmounted
    //   editor.destroy();
    // };
  }, [resultConfig]);

  return (
    <Container fluid className='mt-4'>
      <Row className='m-0 p-0 align-items-end '>
        <Col xs={4} className='m-0 p-0 pe-3'>
          <Row>
            <Col className='m-0'>
              <img id="logo" src={props.logo} alt={props.title} onClick={props.onLogoClick} />
            </Col>
          </Row>
        </Col>
        <Col className='text-end'>
          <Button
            color="success"
            disabled={isSaving}
            onClick={() => {
              setIsSaving(true);
              saveConfig()
            }}>
            Save
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="config" ref={editorRef}></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Config;
