import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Input, Button, Label, Table } from 'reactstrap';
import Loading from '../components/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faDownload, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import ConfirmButton from '../components/ConfirmButton/ConfirmButton';
import DailyReportPreviewEdit from '../components/Preview/DailyReportPreviewEdit';
import * as utilities from '../utilities';

const DailyReportEntries = (props) => {

  const [entries, setEntries] = useState([]);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [search, setSearch] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [viewEntry, setViewEntry] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 50;

  const offsetRef = useRef(0);
  const scrollPositionRef = useRef(0);

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const getEntry = async (entryId) => {
    try {
      const response = await fetch(props.entriesLink+'/'+entryId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + utilities.getAccessToken()
        },
      })

      if (response.ok) {
        const result = await response.json();
        return result;
          // setEntries(result.entries);
      } else {
        utilities.addToast('Unable to load entry.', 5, 'danger');
      }
    } catch (error) {
      console.error('Error fetching entry:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const getEntries = async (force) => {
    if (loading || (!hasMore && !force)) return; // Prevent multiple simultaneous calls
    if (force) {
      setHasMore(true);
      offsetRef.current = 0;
    }
    setLoading(true);
    try {
      const response = await fetch(props.entriesLink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + utilities.getAccessToken()
        },
        body: JSON.stringify({
          start: start,
          end: end,
          search: search,
          offset: offsetRef.current,
          limit: limit
        })
      })

      if (response.ok) {
        const result = await response.json();
        if (force) {
          setEntries(result.entries);
        } else {
          setEntries((prevEntries) => [...prevEntries, ...result.entries]);
        }
        // Update the ref directly
        offsetRef.current += limit;

        if (result.entries.length < limit) {
          setHasMore(false); // No more data to load
        }
      } else {
        utilities.addToast('Unable to load submissions.', 5, 'danger');
      }
    } catch (error) {
      console.error('Error fetching entries:', error);
    } finally {
      setLoading(false);
    }
  };

  const mergeEntries = (updatedEntry) => {
    const tempObj = [...entries];
    const entryIndex = tempObj.findIndex(entry => entry.entryId === updatedEntry.entryId);
    if (entryIndex === -1) return;
    tempObj[entryIndex] = updatedEntry;
    setEntries(tempObj);
  }

  const deleteEntry = (entryId) => {
    const tempObj = [...entries];
    const entryIndex = tempObj.findIndex(entry => entry.entryId === entryId);
    if (entryIndex === -1) return;
    tempObj.splice(entryIndex, 1);
    setEntries(tempObj); 
  }

  const onUpdate = async (id, value, cb) => {
    const response = await fetch(props.updateLink + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify({
        ...value
      })
    })

    if (response.ok) {
      const result = await response.json();
      if (result.data && result.data.length > 0) {
        cb();
        utilities.addToast('Update successful', 5, 'success');
        mergeEntries(result.data[0]);
      }
    } else {
      utilities.addToast('Submission unsuccessful', 5, 'danger');
    }
  }

  const onEdit = async (id, value, prop) => {
    const response = await fetch(props.updateLink + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      },
      body: JSON.stringify({
        [prop]: value ? 0 : 1,
      })
    })

    if (response.ok) {
      const result = await response.json();
      if (result.data && result.data.length > 0) mergeEntries(result.data[0]);
    }
  }

  const onDelete = async (id) => {
    const response = await fetch(props.updateLink + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + utilities.getAccessToken()
      }
    })

    if (response.ok) {
      utilities.addToast('Submission deleted', 5, 'success');
      deleteEntry(id);
    } else {
      utilities.addToast('Unable to delete submission', 5, 'danger');
    }
  }


  const localDate = (date) => {
    return date.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true,
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  const localDateTime = (date) => {
    return date.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  const handleScroll = debounce(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.offsetHeight;

    // Check if user is near the bottom (e.g., within 300px) and not already loading
    if (documentHeight - (scrollTop + windowHeight) < 600 && !loading && hasMore) {
      getEntries();
    }
  }, 100);

  const handleViewClick = async (entry) => {
    scrollPositionRef.current = window.scrollY; // Save the current scroll position
    const result = await getEntry(entry.entryId);
    setViewEntry(result.entry);
    setShowPreview(true);
  };

  const handlePreviewClose = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    if (!showPreview) {
      document.documentElement.style.scrollBehavior = 'auto';
      document.body.style.scrollBehavior = 'auto';

      window.scrollTo({
        top: scrollPositionRef.current,
        behavior: 'auto', // Instant scrolling
      });

      setTimeout(() => {
        document.documentElement.style.scrollBehavior = '';
        document.body.style.scrollBehavior = '';
      }, 0);
    }
  }, [showPreview]);

  useEffect(() => {
    getEntries();
  }, [start, end, search]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 300);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [loading, hasMore]);

  return (
    <Container fluid className='mt-4'>
      {showPreview && viewEntry.data &&
          <DailyReportPreviewEdit
            logo={props.logo}
            isRotated={false}
            hidePreview={handlePreviewClose}
            entry={viewEntry}
            onUpdate={onUpdate}
          />}
      {!showPreview &&
        <>
          <Row className='m-0 p-0 align-items-center'>
            <Col xs={4} className='m-0 p-0 pe-3'>
              <Row>
                <Col className='m-0'>
                  <img id="logo" src={props.logo} alt={props.title} onClick={props.onLogoClick} />
                </Col>
              </Row>
            </Col>
            <Col className='text-end'>
              <h2 className='text-end'>Daily Reports</h2>
            </Col>
          </Row>
          <Row className='mt-3 row-cols-lg-auto g-3 align-items-end'>
            <Col>
              <Label>
                Start
                <Input
                  name="start"
                  type="date"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                >
                </Input>
              </Label>
            </Col>
            <Col>
              <Label>
                End
                <Input
                  name="end"
                  type="date"
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                >
                </Input>
              </Label>
            </Col>
            <Col>
              <Label>
                Search
                <Input
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                >
                </Input>
              </Label>
            </Col>
            <Col>
              <Button color="secondary" onClick={() => {
                setStart('');
                setEnd('');
                setSearch('');
              }}>
                Clear
              </Button>
            </Col>
            <Col>
              <Button color="primary" onClick={() => getEntries(true)}>
                Submit
              </Button>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <Table className='entries table-light'>
                <thead>
                  <tr>
                    <th width="4%" className="downloadedColumn">&nbsp;</th>
                    <th width="5%" >&nbsp;</th>
                    <th width="10%" >Job Number</th>
                    <th width="16%" >Job Name</th>
                    <th width="10%">Date</th>
                    <th width="7%" >RFCO</th>
                    <th width="10%" >Total</th>
                    <th width="4%" className="billedColumn">&nbsp;</th>
                    <th width="13%" >Submitted</th>
                    <th width="4%" className="rfcoCompleteColumn">&nbsp;</th>
                    <th width="13%" >Foreman</th>
                    <th width="4%" className="deleteColumn">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map(entry => (
                    <tr key={entry.entryId}>
                      <td>
                        <Button
                          onClick={() => onEdit(entry.entryId, entry.reportDownloaded === 1, '_ReportDownloaded')}
                          className='faButton'
                          outline={entry.reportDownloaded === 0}
                          color={entry.reportDownloaded === 0 ? 'secondary' : 'success'}
                          title="Downloaded"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </Button>
                      </td>
                      <td><Button color="link" onClick={() => handleViewClick(entry)}>
                        View
                      </Button></td>
                      <td>{entry.data.JobNumber != null ? entry.data.JobNumber : '--'}</td>
                      <td>{entry.data.JobName != null ? entry.data.JobName : '--'}</td>
                      <td>{entry.data.Date != null ? localDate(new Date(entry.data.Date + 'T00:00')) : '--'}</td>
                      <td>{entry.data.RFCO != null ? entry.data.RFCO : '--'}</td>
                      <td>{entry.data.Total != null ? entry.data.Total : '--'}</td>
                      <td>
                        <Button
                          onClick={() => onEdit(entry.entryId, entry.reportBilled === 1, '_ReportBilled')}
                          className='faButton'
                          outline={entry.reportBilled === 0}
                          color={entry.reportBilled === 0 ? 'secondary' : 'success'}
                          title="Billed"
                        >
                          <FontAwesomeIcon icon={faDollarSign} />
                        </Button>
                      </td>
                      <td>{entry.timestamp != null ? localDateTime(new Date(entry.timestamp * 1000)) : '--'}</td>
                      <td>
                        <Button
                          onClick={() => onEdit(entry.entryId, entry.rfcoComplete === 1, '_RFCOComplete')}
                          className='faButton'
                          outline={entry.rfcoComplete === 0}
                          color={entry.rfcoComplete === 0 ? 'secondary' : 'success'}
                          title="RFCO Complete"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      </td>
                      <td><a className='text-primary fw-bold text-decoration-none' href={`mailto:${entry.email}`}>{entry.user}</a></td>
                      <td>
                        <ConfirmButton
                          onClick={() => onDelete(entry.entryId)}
                          className='faButton'
                          color="danger"
                          title="Delete"
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ConfirmButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {loading && <div className="text-center"><Loading /></div>}
            </Col>
          </Row>
        </>}
    </Container>
  );
};

export default DailyReportEntries;
